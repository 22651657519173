<template>
  <!-- <router-view /> -->
  <!-- <sqreen-footer/> -->
  <v-app v-if="!$store.getters.isAuthenticated">
    <core-app-bar @selectLanguageEvent="selectLanguage" v-if="!isShowComponentOnly" />

    <core-drawer v-if="!isShowComponentOnly"/>

    <core-view ref="view" :parentComponent="$refs"/>

    <core-footer v-if="!isShowComponentOnly" />

    <core-cta v-if="!isShowComponentOnly"/>
  </v-app>
  <router-view v-else/>
</template>

<script>
  import { USER_REQUEST } from 'actions/user'
  import { AUTH_LOGOUT } from 'actions/auth'

  export default {
    name: 'App',
    components: {
      CoreCta: () => import('@/components/core/Cta'),
      CoreDrawer: () => import('@/components/core/Drawer'),
      CoreAppBar: () => import('@/components/core/AppBar'),
      CoreFooter: () => import('@/components/core/Footer'),
      CoreView: () => import('@/components/core/View')
      /* CoreCta: () => import('@/components/core/Cta'),
      CoreDrawer: () => import('@/components/core/Drawer'),
      CoreFooter: () => import('@/components/core/Footer'),
      CoreAppBar: () => import('@/components/core/AppBar'),
      CoreView: () => import('@/components/core/View'), */
    },
    computed: {
      isShowComponentOnly () {
        /* return this.$route.name === 'Register' */
        return ['Register', 'Login'].includes(this.$route.name)
        /* return ['Register'].includes(this.$route.path) */
      },
    },
    watch: {
      '$route' () {
        console.log('Route changed:', this.$route.name) // ตรวจสอบว่า route เปลี่ยนจริงหรือไม่
      }
    },
    beforeCreate () {
      // this.$liff.init(function (data) {})
    },
    /* GOlf 2022-12-17 close browser clear localStorage */
    beforeMount () {
      window.addEventListener('beforeunload', this.onUnload)
    },
    beforeDestroy () {
      window.removeEventListener('beforeunload', this.onUnload)
    },
    /* GOlf 2022-12-17 close browser clear localStorage */
    methods: {
      selectLanguage (language) {
        /* this.$refs.navDrawer.getMenu(this.$i18n.locale) */
        this.$refs.view.selectLanguage(language)
      },
      async addVisitCount () {
        await this.axios.post(process.env.VUE_APP_API + '/visit_count/addVisitCount' + process.env.VUE_APP_DATABASE_TYPE, {
        })
          .then(response => {
            if (response.data.isSuccess) {
              var i = ''
              i = 1
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
      /* GOlf 2022-12-17 close browser clear localStorage */
      onUnload (event) {
        this.$store.dispatch(AUTH_LOGOUT)
        /* this.$router.push('/login') */
      },
      /* GOlf 2022-12-17 close browser clear localStorage */
    },
    created: function () {
      this.addVisitCount()
      if (this.$store.getters.isAuthenticated) {
        this.$store.dispatch(USER_REQUEST).then(() => {
          // this.$socket.emit('userConnect', {
          //   userID: localStorage.getItem('username')
          // })
        }).catch((error) => {
          this.$swal({type: 'error', title: error.message})
          this.$store.dispatch(AUTH_LOGOUT)
          this.$router.push('/login')
        })
      }
    }
  }
</script>